import { twMerge } from 'tailwind-merge';

/**
 * ICarouselDotNavigation
 *
 * @interface ICarouselDotNavigation
 */
export interface ICarouselDotNavigation {
  /**
   * The carousel current slide
   *
   * @memberof ICarouselDotNavigation
   * @member {number} currentSlide
   */
  currentSlide: number;
  /**
   * Set carousel slide
   *
   * @memberof ICarouselDotNavigation
   * @member {(index: number) => void} setSlide
   */
  setSlide: (index: number) => void;
  /**
   * The total number of slides
   *
   * @memberof ICarouselDotNavigation
   * @member {number} totalSlides
   */
  totalSlides: number;
  /**
   * The optional font size class
   *
   * @memberof ICarouselDotNavigation
   * @default 'text-[1.5rem]'
   * @member {string} [fontSizeClass]
   */
  fontSizeClass?: string;
  /**
   * The optional classes to add to the component
   *
   * @memberof ICarouselDotNavigation
   * @member {string} [classes]
   */
  classes?: string;
  /**
   * The optional class to add to the dot button
   *
   * @memberof ICarouselDotNavigation
   * @member {string} [dotClass]
   */
  dotClass?: string;
}

/**
 * CarouselDotNavigation A dot navigation for the carousel
 *
 * @param {ICarouselDotNavigation} props - The props for the
 *   CarouselDotNavigation component
 * @returns {React.FC<ICarouselDotNavigation>} Component
 */
const CarouselDotNavigation: React.FC<ICarouselDotNavigation> = ({
  currentSlide,
  setSlide,
  totalSlides,
  fontSizeClass = 'text-[1.5rem]',
  classes = '',
  dotClass = '',
}: ICarouselDotNavigation) => {
  return (
    <div
      data-testid="carousel-dot-navigation"
      className={twMerge(
        'font-petco space-x-2 text-center flex w-full justify-center absolute bottom-0',
        fontSizeClass,
        classes
      )}
    >
      {Array.from({ length: totalSlides }, (_, index) => {
        /**
         * The active state of the current dot
         *
         * @constant {boolean} isActiveSlide
         */
        const isActiveSlide = index === currentSlide;

        return (
          <button
            key={index}
            onClick={() => setSlide(index)}
            data-testid="carousel-dot-button"
            className={twMerge(
              'text-neutral-100 h-[38px]',
              !isActiveSlide ? 'opacity-40' : '',
              dotClass
            )}
          >
            •<span className="sr-only">Jump to Item {index + 1}</span>
          </button>
        );
      })}
    </div>
  );
};

export default CarouselDotNavigation;
