import Blob, { BlobType } from '@/components/atoms/Blob/Blob';
import ButtonLink from '@/components/atoms/ButtonLink/ButtonLink';
import { ColorType } from '@/components/atoms/Colors/Colors';
import Icon, { IconType } from '@/components/atoms/Icon/Icon';
import React, { HTMLAttributeAnchorTarget } from 'react';

/**
 * IActionLink
 *
 * @interface IActionLink
 */
interface IActionLink {
  /**
   * The label
   *
   * @memberof IActionLink
   * @member {string} label
   */
  label: string;
  /**
   * The url
   *
   * @memberof IActionLink
   * @member {string} url
   */
  url: string;
  /**
   * The target
   *
   * @memberof IActionLink
   * @member {HTMLAttributeAnchorTarget} target
   */
  target: HTMLAttributeAnchorTarget;
}

/**
 * IIconCard
 *
 * @interface IIconCard
 */
export interface IIconCard {
  /**
   * The title to display.
   *
   * @memberof IIconCard
   * @member {string} title
   */
  title: string;
  /**
   * The description to display.
   *
   * @memberof IIconCard
   * @member {string | React.ReactNode} description
   */
  description: string | React.ReactNode;
  /**
   * The blob to display
   *
   * @memberof IIconCard
   * @member {BlobType} blob
   */
  blob: BlobType;
  /**
   * The icon to display
   *
   * @memberof IIconCard
   * @member {IconType} icon
   */
  icon: IconType;
  /**
   * The color for the icon
   *
   * @memberof IIconCard
   * @default 'base'
   * @member {ColorType} [iconColor]
   */
  iconColor?: ColorType;
  /**
   * The optional classes to apply to the component.
   *
   * @memberof IIconCard
   * @default ''
   * @member {string} [className]
   */
  className?: string;
  /**
   * The optional hideBlob
   *
   * @memberof IIconCard
   * @default false
   * @member {boolean} [hideBlob]
   */
  hideBlob?: boolean;
  /**
   * The optional blobBackgroundColor
   *
   * @memberof IIconCard
   * @default 'base'
   * @member {ColorType} [blobBackgroundColor]
   */
  blobBackgroundColor?: ColorType;
  /**
   * The optional action link
   *
   * @memberof IIconCard
   * @member {IActionLink} [actionLink]
   */
  actionLink?: IActionLink;
}

/**
 * IIconCard Displays an image, title and description. Description is passed in
 * as either a string or a React Node so things like text with links can be
 * passed in
 *
 * @param {IIconCard} props - The props for the IconCard component
 * @returns {React.FC<IIconCard>} IIconCard Component
 */
const IconCard: React.FC<IIconCard> = ({
  title,
  description,
  blob,
  icon,
  iconColor = 'base',
  className = '',
  hideBlob = false,
  blobBackgroundColor = 'base',
  actionLink,
}: IIconCard) => {
  return (
    <div
      data-testid="icon-card"
      className={`w-full px-6 mb:px-8 text-center ${className}`}
    >
      <div className="relative h-[114px] mb-6 md:mb-8 flex items-center justify-center">
        {!hideBlob && (
          <Blob
            blob={blob}
            size={114}
            colorType={blobBackgroundColor}
            colorShade={100}
          />
        )}
        <Icon
          icon={icon}
          size={80}
          colorType={iconColor}
          colorShade={400}
          classes="absolute"
        />
      </div>
      <span className="text-h5 block mb-4 text-mkNavy-400 font-bold font-petco">
        {title}
      </span>
      <div className="[&>p>a]:underline [&>p>a]:hover:text-base-300">
        {description}
      </div>
      {actionLink && (
        <ButtonLink
          variant="light"
          href={actionLink.url}
          className="[&>a]:text-mkNavy-400 mt-6"
          target={actionLink.target}
        >
          {actionLink.label}
        </ButtonLink>
      )}
    </div>
  );
};

export default IconCard;
