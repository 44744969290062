import ContentfulImage from '@/components/atoms/ContentfulImage/ContentfulImage';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import Link from 'next/link';

/**
 * IPetAndOwnerCard Interface for the Pet And Owner Card Component
 *
 * @interface IPetAndOwnerCard
 */
export interface IPetAndOwnerCard {
  /**
   * The Name of the Pet
   *
   * @memberof IPetAndOwnerCard
   * @member {string} petName
   */
  petName: string;
  /**
   * The Name of the Owner
   *
   * @memberof IPetAndOwnerCard
   * @member {string} ownerName
   */
  ownerName: string;
  /**
   * The Description of the Card
   *
   * @memberof IPetAndOwnerCard
   * @member {string} description
   */
  description: string;
  /**
   * The Image of the Card
   *
   * @memberof IPetAndOwnerCard
   * @member {string} image
   */
  image: string;
  /**
   * The Link for the Card
   *
   * @memberof IPetAndOwnerCard
   * @member {string} link
   */
  link: string;
}

/**
 * PetAndOwnerCard Component
 *
 * @param {IPetAndOwnerCard} props - The props for the Pet And Owner Card
 * @returns {React.FC<IPetAndOwnerCard>} PetAndOwnerCard Component
 */
const PetAndOwnerCard: React.FC<IPetAndOwnerCard> = ({
  petName,
  ownerName,
  description,
  image,
  link,
}: IPetAndOwnerCard) => {
  return (
    <Link href={link} className="focus-visible:outline-none py-[5px] w-full">
      <div
        data-testid="pet-and-owner-card"
        className="group flex flex-col justify-center items-center focus-visible:outline-focus-400"
      >
        <div className="aspect-square overflow-hidden rounded-[20px] md:rounded-3xl w-full relative">
          <ContentfulImage
            layout="fill"
            objectFit="cover"
            alt={petName + ' + ' + ownerName}
            src={image}
            className="scale-100 group-hover:scale-[102%] transition-all duration-100"
            sizes="(max-width: 739px) 100vw, (max-width: 1198px) 50vw, (max-width: 1439px) 33vw, 450px"
          />
        </div>

        <span className="mt-3.5 line-clamp-1 text-h5 font-petco font-semibold text-secondaryBase-400">
          {petName} <span className="text-base-300">+</span> {ownerName}
        </span>
        <Paragraph
          size="body4"
          font="petco"
          className="mt-2 text-center line-clamp-2 font-petco max-w-[432px]"
        >
          {description}
        </Paragraph>
      </div>
    </Link>
  );
};

export default PetAndOwnerCard;
