import { notApplicableString } from '@/lib/constants/constants/analytics';
import { analyticsFeatureKey } from '@/lib/utils/featureFlags/optimizely/featureFlagOverride';
import CookieStorage from '@/lib/utils/storage/cookie-storage';

/** @typedef validTypes - Valid types for event data */
type ValidTypes = string | number | boolean | object | null | undefined;

/** @typedef eventData - How to pass the data within the event */
type EventData = { [key: string]: ValidTypes };

/**
 * Describes the base event structure
 *
 * @interface IEvent
 */
export interface IEvent {
  /**
   * The name of the event
   *
   * @memberof IEvent
   * @member {string} name
   */
  name: string;
  /**
   * The data associated with the event
   *
   * @memberof IEvent
   * @member {EventData} data - The data associated with the event
   */
  data: EventData;
}

/**
 * Provides an abstract class for analytics handlers
 *
 * @abstract
 * @class
 * @name analyticsHandler
 */
export default abstract class AbstractAnalyticsHandler {
  /**
   * The source platform of the event data
   *
   * @memberof AbstractAnalyticsHandler
   * @member {string} brand
   */
  brand: string;

  /**
   * @memberof AbstractAnalyticsHandler
   * @param {string} brand - The source platform of the event data
   */
  constructor(brand: string) {
    this.brand = brand;
  }

  /**
   * @memberof AbstractAnalyticsHandler
   * @returns {string} - The analytic feature that is enabled
   * @protected
   */
  protected getAnalyticsFeature(): string {
    const cookies = new CookieStorage();
    const optimizelyDecision = cookies.get(analyticsFeatureKey) as {
      /** The analytic feature that is enabled */
      analytic: string;
    };
    let feature: string = notApplicableString;
    if (optimizelyDecision && optimizelyDecision.analytic) {
      // Split the string into an array
      feature = optimizelyDecision.analytic;
    }
    return feature;
  }

  /**
   * @memberof AbstractAnalyticsHandler
   * @abstract
   * @param {IEvent} data - The data to be sent with the event
   */
  abstract send(data: IEvent): void;
}
