import { BlobType } from '@/components/atoms/Blob/Blob';
import { IconType } from '@/components/atoms/Icon/Icon';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import IconCard from '@/components/molecules/IconCard/IconCard';
import { ContainerProps } from '@/lib/dataSource/contentful/containers';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

/**
 * @param {object} props - The props for the IconCard
 * @returns {React.ReactNode} - The IconCard Container
 */
export const IconCardContainer = (props: ContainerProps['iconCard']) => {
  const {
    title,
    copyRichText,
    blob,
    icon,
    iconColor,
    iconBackgroundColor,
    removeBackground,
    actionLink,
  } = props.fields;

  let selectedBlob;
  switch (blob) {
    case 'Icon background 1':
      selectedBlob = 'blobOne';
      break;
    case 'Icon background 2':
      selectedBlob = 'blobTwo';
      break;
    case 'Icon background 3':
      selectedBlob = 'blobThree';
      break;
    case 'Icon background 4':
      selectedBlob = 'blobFour';
      break;
  }

  let selectedBackgroundColor;
  switch (iconBackgroundColor) {
    case 'Light Purple':
      selectedBackgroundColor = 'base';
      break;
    case 'Light Green':
      selectedBackgroundColor = 'mkLightGreen';
      break;
  }

  let selectedIconColor;
  switch (iconColor) {
    case 'Primary Purple':
      selectedIconColor = 'base';
      break;
    case 'Primary Navy':
      selectedIconColor = 'mkNavy';
      break;
  }

  return (
    <IconCard
      title={title}
      description={
        copyRichText
          ? documentToReactComponents(copyRichText, {
              renderNode: {
                /**
                 * @param {object} _ - Node Data
                 * @param {React.ReactNode} children - Node Children Tree
                 * @returns {React.ReactNode} - ReactNode
                 */
                [BLOCKS.PARAGRAPH]: (_, children) => (
                  <Paragraph size="body4">{children}</Paragraph>
                ),
              },
            })
          : ''
      }
      blob={selectedBlob as BlobType}
      icon={`illustration${icon}` as IconType}
      iconColor={selectedIconColor}
      hideBlob={removeBackground}
      blobBackgroundColor={selectedBackgroundColor}
      actionLink={actionLink?.fields}
    />
  );
};
