import Divider from '@/components/atoms/Divider/Divider';
import Heading from '@/components/atoms/Heading/Heading';
import Icon from '@/components/atoms/Icon/Icon';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import NotificationRow, {
  INotificationRow,
} from '@/components/molecules/NotificationRow/NotificationRow';
import { twMerge } from 'tailwind-merge';

/**
 * INotificationDrawer
 *
 * @interface INotificationDrawer
 */
export interface INotificationDrawer {
  /**
   * The notifications to be displayed in the drawer
   *
   * @memberof INotificationDrawer
   * @member {INotificationRow[]} [notifications]
   */
  notifications?: INotificationRow[];
  /**
   * The optional className
   *
   * @memberof INotificationDrawer
   * @member {string} [className]
   */
  className?: string;
  /**
   * The optional button click handler
   *
   * @memberof INotificationDrawer
   * @member {() => void} [onButtonClick]
   */
  onButtonClick?: () => void;
  /**
   * Close the notification Drawer
   *
   * @memberof INotificationDrawer
   * @member {() => void} [closeDrawer]
   */
  closeDrawer?: () => void;
}

/**
 * NotificationDrawer A drawer used to contain a list of notifications
 *
 * @param {INotificationDrawer} props - The props for the NotificationDrawer
 *   component
 * @returns {React.FC<INotificationDrawer>} Component
 */
const NotificationDrawer: React.FC<INotificationDrawer> = ({
  notifications,
  className = '',
  onButtonClick,
  closeDrawer,
}: INotificationDrawer) => {
  return (
    <div
      data-testid="notification-drawer"
      className={twMerge(
        'flex flex-col w-screen sm:w-[376px] h-screen sm:h-[550px] bg-neutral-100 pl-6 pr-2 overflow-hidden sm:shadow-[0px_12px_16px_0px_rgba(0,0,0,0.12)] absolute rounded-b-2xl z-[12] top-0',
        className
      )}
    >
      <div className="flex justify-between items-center sticky top-0 bg-neutral-100 sm:hidden pb-5 pt-4 pr-4">
        <Heading size="h5" className="text-secondaryBase-400">
          Notifications
        </Heading>
        <button
          data-testid="close-button"
          className="text-secondaryBase-400"
          onClick={onButtonClick}
        >
          <div className="flex flex-row items-center">
            <Icon
              icon="close"
              size={20}
              colorType="neutral"
              colorShade={800}
              classes="px-1"
            />
            <Paragraph size="body5">Close</Paragraph>
          </div>
        </button>
      </div>
      <div className="flex flex-col h-full overflow-y-auto">
        <div className="justify-start items-center bg-neutral-100 pt-6 pb-4 z-[11] hidden sm:flex">
          <Heading size="h5" className="text-secondaryBase-400">
            Notifications
          </Heading>
        </div>
        {notifications && notifications.length > 0 ? (
          notifications.map((notification, index) => (
            <div key={index} className="pr-4">
              <Divider type="lightGrey" />
              <NotificationRow {...notification} closeDrawer={closeDrawer} />
            </div>
          ))
        ) : (
          <div
            data-testid="empty-notifications"
            className="flex flex-col w-full pr-4"
          >
            <Divider type="lightGrey" />
            <Paragraph size="body5" className="text-neutral-800 pt-4">
              Keep an eye out here for new notifications!
            </Paragraph>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationDrawer;
