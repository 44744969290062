import { notApplicableString } from '@/lib/constants/constants/analytics';
import { defaultExpirationDate } from '@/lib/constants/constants/storage';
import { EventData } from '@/lib/hooks/analytics/useEventContext';
import { tomorrow } from '@/lib/utils/helpers/dateHelpers/dates';
import LocalStorage from '@/lib/utils/storage/local-storage';

/**
 * The valid type for the initiating component param
 *
 * @type {AnalyticsInitiatingComponent}
 */
export type AnalyticsInitiatingComponent =
  | 'I Lost A Pet Button / Homepage'
  | 'I Found A Pet Button / Homepage'
  | 'I Lost A Pet Footer Link / All Pages'
  | 'I Found A Pet Footer Link / All Pages'
  | 'Search All Pets Link / Homepage'
  | 'Search All Pets Link / How To Help Page'
  | 'Search All Pets Button / How To Help Page'
  | 'Sign Up Button / All Pages'
  | 'Search Persistent Banner / All Pages'
  | 'I Found A Pet Button / Shelter Dashboard'
  | 'I Found A Pet Button / Shelter Homepage'
  | 'Search Button / User Dashboard'
  | 'Photo Search Chip / Search Results Page'
  | 'Microchip Search Chip / Search Results Page'
  | 'Distance Search Chip / Search Results Page'
  | 'Photo Search Button / Search Results Page'
  | 'Microchip Search Button / Search Results Page'
  | 'Distance Search Button / Search Results Page'
  | 'Photo Search Link / Search Results Page'
  | 'Microchip Search Link / Search Results Page'
  | 'Distance Search Link / Search Results Page'
  | 'Search By Microchip Link / Lost and Found Tips Page'
  | 'Contact Pet Finder Button'
  | 'Contact Pet Parent Button'
  | 'View Reported Pets Near Me / Alert Matches Page'
  | 'View Matches Button / Shelter Pets Page'
  | 'View Matches Button / Shelter Pet Profile'
  | 'Report Pet Tab / Photo Search Results Page / Lost'
  | 'Report Pet Tab / Distance Search Results Page / Lost'
  | 'Report Pet Tab / Photo Search Results Page / Found'
  | 'Report Pet Tab / Distance Search Results Page / Found'
  | 'Card Header Link / Photo Search Results Page / Lost'
  | 'Card Header Link / Photo Search Results Page / Found'
  | 'Card Header Link / Distance Search Results Page / Lost'
  | 'Card Header Link / Distance Search Results Page / Found'
  | 'Card Header Link / Microchip Search Results Page / Lost'
  | 'Card Header Link / Microchip Search Results Page / Found'
  | 'Add Pet Button / User Dashboard / Lost'
  | 'Add Pet Button / User Dashboard / Found'
  | 'Report as Lost Button / User Dashboard / Lost'
  | typeof notApplicableString;

/**
 * The initiating component data
 *
 * @interface IAnalyticsInitiatingComponentData
 */
interface IAnalyticsInitiatingComponentData {
  /** The initiating component */
  component: AnalyticsInitiatingComponent;
  /** The data expiration date */
  expiration: Date;
}

/**
 * AnalyticsEventStorage class, this is used to store the analytics event
 * information that is not present in the context of certain pages
 *
 * @class AnalyticsEventStorage
 */
export class AnalyticsEventStorage {
  /** The session storage instance */
  private readonly analyticsEventStorage = new LocalStorage();

  /** The key to store the analytics event data under */
  private readonly analyticsEventStorageKey = 'analyticsEvent';

  private readonly initiatingComponentKey = 'initiatingAnalyticsComponent';

  /**
   * Set data in session storage
   *
   * @param {EventData} analyticsEvent - The analytics event object to store
   */
  public set = (analyticsEvent: EventData) => {
    this.analyticsEventStorage.set(
      this.analyticsEventStorageKey,
      analyticsEvent,
      { expires: tomorrow }
    );
  };

  /**
   * Get data from session storage
   *
   * @returns {EventData | null} The analytics event object
   */
  public get = (): EventData | null => {
    return this.analyticsEventStorage.get(
      this.analyticsEventStorageKey
    ) as EventData;
  };

  /** Delete data from session storage */
  public delete = () => {
    this.analyticsEventStorage.delete(this.analyticsEventStorageKey);
  };

  /**
   * Set the initiating component
   *
   * @param {string} component - The initiating component
   */
  public setInitiatingComponent = (component: AnalyticsInitiatingComponent) => {
    this.analyticsEventStorage.set(
      this.initiatingComponentKey,
      { component },
      { expires: defaultExpirationDate }
    );
  };

  /**
   * Retrieve the initiating component
   *
   * @constant {AnalyticsInitiatingComponent} initiatingComponent
   * @returns {AnalyticsInitiatingComponent} The initiating component
   */
  public getInitiatingComponent = (): AnalyticsInitiatingComponent => {
    /**
     * The analytics initiating component data
     *
     * @constant {IAnalyticsInitiatingComponentData} data
     */
    const data = this.analyticsEventStorage.get(
      this.initiatingComponentKey
    ) as IAnalyticsInitiatingComponentData;
    /**
     * The initiating component
     *
     * @constant {AnalyticsInitiatingComponent} component
     */
    const component = data?.component;

    if (component) {
      return component;
    }

    return 'NA';
  };
}
