import { featureFlagOverrideKey } from '@/lib/utils/featureFlags/optimizely/featureFlagOverride';
import CookieStorage from '@/lib/utils/storage/cookie-storage';
import { useDecision } from '@optimizely/react-sdk';
import { useEffect, useState } from 'react';

/**
 * Hook to get the enabled features
 *
 * @param {string} flag - The flag to check
 * @returns {boolean} Is the feature enabled
 */
export const useOptimizelyFeature = (flag: string): boolean => {
  const cookies = new CookieStorage();
  const overrideCookie = cookies.get(featureFlagOverrideKey);
  const [enabled, setEnabled] = useState(() => overrideCookie === flag);
  const [decision, clientReady] = useDecision(flag);

  useEffect(() => {
    // Check if optimizely has the feature flag and if the client is ready
    if (clientReady && decision.enabled === true) {
      setEnabled(true);
    }
  }, [decision, clientReady]);
  return enabled;
};
