import AccentProvider from '@/lib/hooks/accentContext/AccentContext';
import { EventContextProvider } from '@/lib/hooks/analytics/useEventContext';
import OverlayProvider from '@/lib/hooks/overlayContext/OverlayContext';
import { PersistentBannerProvider } from '@/lib/hooks/persistentBanner/PersistentBanner';
import { ShelterLocationProvider } from '@/lib/hooks/shelterDashboard/ShelterLocation';
import { ShelterReferralProvider } from '@/lib/hooks/shelterReferral/ShelterReferral';
import { ToastProvider } from '@/lib/hooks/toasts/Toast';
import { UserContextProvider } from '@/lib/hooks/userContext/UserContext';
import getBasePath from '@/lib/utils/getBasePath/getBasePath';
import CookieStorage from '@/lib/utils/storage/cookie-storage';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { OptimizelyProvider, createInstance } from '@optimizely/react-sdk';
import { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

/**
 * IProviderLayout
 *
 * @interface IProviderLayout
 */
export interface IProviderLayout {
  /**
   * Content of the Layout
   *
   * @memberof IProviderLayout
   * @member {ReactNode} children
   */
  children: ReactNode;
}

const optimizelyClient = createInstance({
  datafile: require('@/lib/utils/featureFlags/optimizely/datafile.json'),
  eventBatchSize: 1,
  eventFlushInterval: 1000,
  logLevel: 4,
});

const cookies = new CookieStorage();

const clientSetUserIdKeyCookie = cookies
  .get('feature-flag-user-id')
  ?.toString();
// Get the user id from the cookies
const userId = clientSetUserIdKeyCookie || uuidv4();

if (!clientSetUserIdKeyCookie) {
  cookies.set('feature-flag-user-id', userId);
}

/**
 * Provider Layout Used to provide all required top level providers to the
 * application
 *
 * @param {IProviderLayout} props - The props for the ProviderLayout component.
 * @returns {React.FC<IProviderLayout>} ProviderLayout Component
 */
const ProviderLayout: React.FC<IProviderLayout> = ({
  children,
}: IProviderLayout) => {
  return (
    <UserProvider profileUrl={`${getBasePath()}/api/auth/me`}>
      <OptimizelyProvider
        optimizely={optimizelyClient}
        user={{ id: userId }}
        isServerSide={true}
      >
        <UserContextProvider>
          <PersistentBannerProvider>
            <EventContextProvider>
              <ToastProvider>
                <OverlayProvider defaultOverlay={false}>
                  <AccentProvider>
                    <ShelterReferralProvider>
                      <ShelterLocationProvider>
                        {children}
                      </ShelterLocationProvider>
                    </ShelterReferralProvider>
                  </AccentProvider>
                </OverlayProvider>
              </ToastProvider>
            </EventContextProvider>
          </PersistentBannerProvider>
        </UserContextProvider>
      </OptimizelyProvider>
    </UserProvider>
  );
};

export default ProviderLayout;
