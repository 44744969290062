import { IButton } from '@/components/atoms/Button/Button';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import PageWrapper from '@/components/organisms/PageWrapper/PageWrapper';
import SideBySide from '@/components/organisms/SideBySide/SideBySide';
import { ContainerProps } from '@/lib/dataSource/contentful/containers';
import { anchorTarget } from '@/lib/utils/anchorTarget';
import { addHttps } from '@/lib/utils/stringReplace/addHttps';
import {
  documentToReactComponents,
  RenderNode,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types';
import { ReactNode } from 'react';

/**
 * @param {object} props - SideBySideContainerProps
 * @returns {React.ReactNode} - SideBySideContainer
 */
export const SideBySideContainer = (
  props: ContainerProps['sideBySide'] & {
    /** Classname */
    className?: string;
  }
) => {
  const button1 = props.fields.actionLink1
    ? ({
        children: props.fields.actionLink1?.fields?.label,
        color: 'primary',
        size: 'md',
        type: 'button',
        href: props.fields.actionLink1?.fields?.url,
        newTab:
          props.fields.actionLink1?.fields?.target === '_blank' ? true : false,
      } as IButton)
    : undefined;

  const button2 = props.fields.actionLink2
    ? ({
        children: props.fields.actionLink2?.fields?.label,
        color: 'secondary',
        size: 'md',
        type: 'button',
        href: props.fields.actionLink2?.fields?.url,
        newTab:
          props.fields.actionLink2?.fields?.target === '_blank' ? true : false,
      } as IButton)
    : undefined;

  return (
    <PageWrapper classes="page-max-w px-6 sm:px-8 md:px-10">
      <SideBySide
        title={documentToReactComponents(props.fields?.headline, {
          renderMark: {
            // eslint-disable-next-line jsdoc/require-jsdoc
            bold: (text) => <strong className="font-petco">{text}</strong>,
          },
          renderNode: {
            /**
             * Embedded Asset
             *
             * @param {Document} node - The node
             * @param {string} children - The children
             * @returns {React.ReactNode} - Image Component
             */
            [BLOCKS.PARAGRAPH]: (node: Document, children: string) => (
              <span className="text-inherit">{children}</span>
            ),
          } as unknown as RenderNode,
        })}
        body={documentToReactComponents(props.fields.copyRichText, {
          renderNode: {
            /**
             * @param {object} _ - The node
             * @param {ReactNode} children - The children
             * @returns {React.ReactNode} - Paragraph Component
             */
            [BLOCKS.PARAGRAPH]: (_, children: ReactNode) => (
              <Paragraph size="body3" font="petco" className="mb-4">
                {children}
              </Paragraph>
            ),
            /**
             * @param {object} node - Node Data
             * @param {object} node.data - Node Data
             * @param {React.ReactNode} children - Node Children Tree
             * @returns {React.ReactNode | void} - ReactNode
             */
            [INLINES.HYPERLINK]: ({ data }, children) => {
              return anchorTarget(data.uri, children);
            },
          },
          renderMark: {
            /**
             * @param {string} text - The text to render
             * @returns {React.ReactNode} - The bold text
             */
            bold: (text) => <span className="font-bold">{text}</span>,

            /**
             * @param {string} text - The text to render
             * @returns {React.ReactNode} - The italicized text
             */
            italic: (text) => <span className="font-light italic">{text}</span>,
          },
        })}
        image={{
          url: addHttps(props.fields.image?.fields.file?.url),
          alt: props.fields.image?.fields.description,
          width: props.fields.image?.fields.file?.details.image?.width || 560,
          height: props.fields.image?.fields.file?.details.image?.height || 430,
        }}
        button1={button1}
        button2={button2}
        imagePosition={props.fields.imagePosition}
      />
    </PageWrapper>
  );
};
