/**
 * The feature flag for the search all page
 *
 * @constant {string}
 */
export const searchAllFeature = 'test_flag_search_all';

/**
 * The feature flag for the shelter multi users functionality.
 *
 * @constant {string}
 */
export const flagShelterMultiUsers = 'test_flag_shelter_multi_users';

/**
 * The feature flag for the persistent search.
 *
 * @constant {string}
 */
export const flagPersistentSearch = 'test_flag_persistent_search';

/**
 * The feature flag for the persistent search used by shelters
 *
 * @constant {string}
 */
export const flagShelterPersistentSearch =
  'test_flag_persistent_search_shelter';

/**
 * The feature flag for the map search.
 *
 * @constant {string}
 */
export const flagMapSearch = 'test_flag_map_search';

/**
 * The feature flag for the map version of search-all.
 *
 * @constant {string}
 */
export const flagMapPages = 'test_flag_map_pages';

/**
 * The feature flag for the map search results page.
 *
 * @constant {string}
 */
export const flagMapSearchResults = 'test_flag_map_search_results';

/**
 * The feature flag for the microchip creation search.
 *
 * @constant {string}
 */
export const flagMicrochipCreationSearch =
  'test_flag_microchip_creation_search';

/**
 * The feature flag for the facebook integration.
 *
 * @constant {string}
 */
export const flagFacebookIntegration = 'test_flag_facebook_integration';

/**
 * The feature flag for the shelter dashboard reporting.
 *
 * @constant {string}
 */
export const flagShelterDashboardReporting =
  'test_flag_shelter_dashboard_reporting';

/**
 * The feature flag for showing the disruptor tab in the search results page.
 *
 * @constant {string}
 */
export const flagDisruptorTab = 'test_flag_disruptor_search_tab';

/**
 * The feature flag for the static map in the address step.
 *
 * @constant {string}
 */
export const flagStaticMapInAddressStep =
  'test_flag_static_map_in_address_step';
