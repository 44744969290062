import { NextRequest } from 'next/server';

export const featureFlagOverrideKey = 'feature-flag-override';
/**
 * The variable name to look for on the feature flags to determine if the
 * analytics feature is enabled
 *
 * @constant {string} analyticsFeatureVariable
 */
export const analyticsFeatureVariable = 'to_analytics';

/**
 * The key to use in the cookies to store the analytics feature flag
 *
 * @constant {string} analyticsFeatureKey
 */
export const analyticsFeatureKey = 'analytics_feature';

/**
 * Look for a feature flag over ride in the cookies
 *
 * @param {NextRequest} request - The incoming request
 * @returns {string | null} - The feature flag override if present, otherwise
 *   null
 */
export function getFeatureFlagOverride(request: NextRequest): string | null {
  // Look for the feature flag override in the cookies
  const overrideCookie = request.cookies.get(featureFlagOverrideKey);
  if (overrideCookie) {
    return overrideCookie.value;
  }
  // Return null if no feature flag override is found
  return null;
}
