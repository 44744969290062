import { defaultEventEmitter } from '@/lib/analytics/commonEmitter';
import { IEvent } from '@/lib/analytics/handlers/abstractAnalyticsHandler';

/**
 * The data object for the view pet match alert event
 *
 * @interface IViewPetMatchAlertEventData
 */
export interface IViewPetMatchAlertEventData {
  /**
   * The event Id
   *
   * @memberof IViewPetMatchAlertEventData
   * @member {string} eventId
   */
  eventId: string;
  /**
   * The platform for the alert
   *
   * @memberof IViewPetMatchAlertEventData
   * @member {'app' | 'email' | 'sms'} platform
   */
  platform: 'app' | 'email' | 'sms';
  /**
   * The user Id
   *
   * @memberof IViewPetMatchAlertEventData
   * @member {number} [userId]
   */
  userId: number;
}
/**
 * Send the view pet match alert event to google analytics
 *
 * @param {IViewPetMatchAlertEventData} data - The data to send
 */
export const viewPetMatchAlertEventHandler = ({
  eventId,
  platform,
  userId,
}: IViewPetMatchAlertEventData) => {
  /** Create the event data object */
  const eventData: IEvent = {
    name: 'view_pet_match_alert',
    data: {
      user_id: userId,
      items: [
        {
          alert_event_id: eventId,
          platform,
        },
      ],
    },
  };
  defaultEventEmitter.handleEvent(eventData);
};
