import useMe from '@/lib/hooks/me/useMe';
import CookieStorage from '@/lib/utils/storage/cookie-storage';
import React, { createContext, useContext, useEffect, useState } from 'react';

export const shelterLocationStorageKey = 'selectedShelterLocation';

/**
 * The interface for the shelter location storage
 *
 * @interface
 */
export interface IShelterLocationStorage {
  /** The selected shelter id */
  selectedShelterId?: number;
}

/**
 * The shelter location storage class is used to deal with the storage for the
 * selected shelter location.
 */
export class ShelterLocationStorage {
  /** The storage used in the class. */
  readonly storage = new CookieStorage();

  /** The key used for the storage. */
  readonly key = shelterLocationStorageKey;

  /**
   * A method used to store the shelter location in storage
   *
   * @param {IShelterLocationStorage} data - The shelter location to store
   */
  public set = (data: IShelterLocationStorage) => {
    this.storage.set(this.key, data);
  };

  /** A method used to remove the banner data from storage */
  public delete = () => {
    this.storage.delete(this.key);
  };

  /**
   * A method used to get the banner data from storage
   *
   * @returns {IShelterLocationStorage | null} The banner props data
   */
  public get = (): IShelterLocationStorage | null => {
    return this.storage.get(this.key) as IShelterLocationStorage;
  };
}

/** The interface for a shelter item */
export interface IShelterLocationItem {
  /** The shelter id */
  id: number;
  /** The shelter name */
  name: string;
}

/** The interface for the shelter location context */
export interface IShelterLocationContext {
  /**
   * The shelter list
   *
   * @memberof IShelterLocationContext
   * @member {IShelterLocationItem[]} [shelterList]
   */
  shelterList?: IShelterLocationItem[];
  /**
   * The selected shelter id
   *
   * @memberof IShelterLocationContext
   * @member {number} [selectedShelterId]
   */
  selectedShelterId?: number;
  /**
   * The selected shelter object
   *
   * @memberof IShelterLocationContext
   * @member {IShelterLocationItem} [selectedShelter]
   */
  selectedShelter?: IShelterLocationItem;
  /**
   * Whether the shelter list is being fetched
   *
   * @memberof IShelterLocationContext
   * @member {boolean} isFetchingShelterList
   */
  isFetchingShelterList: boolean;
  /**
   * The handle shelter selection function
   *
   * @memberof IShelterLocationContext
   * @member {Function} handleShelterSelection
   */
  handleShelterSelection?: (id: number) => void;
  /**
   * Whether the user is switching locations
   *
   * @memberof IShelterLocationContext
   * @member {boolean} isSwitchingLocation
   */
  isSwitchingLocation: boolean;
  /**
   * The set is switching location function
   *
   * @memberof IShelterLocationContext
   * @member {Function} [setIsSwitchingLocation]
   */
  setIsSwitchingLocation?: (isSwitchingLocation: boolean) => void;
  /**
   * The selected shelter id for the shelter reporting
   *
   * @memberof IShelterLocationContext
   * @member {number | null} [selectedShelterIdForShelterReporting]
   */
  selectedShelterIdForShelterReporting?: number | null;
  /**
   * The setter function for the SelectedShelterIdForShelterReporting state
   *
   * @memberof IShelterLocationContext
   * @member {React.Dispatch} [setSelectedShelterIdForShelterReporting]
   */
  setSelectedShelterIdForShelterReporting?: React.Dispatch<
    React.SetStateAction<number | null>
  >;
}

const ShelterLocationContext = createContext<IShelterLocationContext>({
  shelterList: undefined,
  selectedShelterId: undefined,
  isFetchingShelterList: true,
  selectedShelter: undefined,
  isSwitchingLocation: false,
});

/**
 * The interface for the shelter location provider
 *
 * @interface
 */
interface IShelterLocationProvider {
  /** The initial shelter list. Used for testing. */
  initialShelterList?: IShelterLocationItem[];
  /** The children wrapped by the provider */
  children: React.ReactNode;
}

/**
 * The Shelter Location provider
 *
 * @param {React.ReactNode} children - The children of the component
 * @returns {React.ReactNode} The Shelter Location provider
 */
export function ShelterLocationProvider({
  initialShelterList,
  children,
}: IShelterLocationProvider) {
  const { me, isFetchingUser } = useMe();

  const storage = new ShelterLocationStorage();

  const [selectedShelterId, setSelectedShelterId] =
    useState<IShelterLocationStorage['selectedShelterId']>();

  const [isSwitchingLocation, setIsSwitchingLocation] = useState(false);

  const [
    selectedShelterIdForShelterReporting,
    setSelectedShelterIdForShelterReporting,
  ] = useState<number | null>(null);

  /**
   * Handles the selection of a shelter in the list.
   *
   * @param {string} id - The id of the shelter.
   */
  function handleShelterSelection(id: number) {
    setSelectedShelterId(id);
    storage.set({ selectedShelterId: id });
  }

  const shelterList = initialShelterList ? initialShelterList : me?.awos;

  const selectedShelter = shelterList?.find(
    (shelter) => shelter.id === Number(selectedShelterId)
  );

  // If no data on storage, set the first item of the shelter list array as the selected shelter
  useEffect(() => {
    const firstShelterId = shelterList?.[0]?.id;
    const data = storage.get();

    if (!data?.selectedShelterId && firstShelterId) {
      handleShelterSelection(firstShelterId);
    }
    /** Adding other fields would cause an infinite loop. */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shelterList?.length]);

  // Check if the selected shelter id is valid and if not, set the first shelter id as the selected shelter
  useEffect(() => {
    const data = storage.get();

    if (data?.selectedShelterId && shelterList) {
      const shelterIds = shelterList?.map((shelter) => shelter.id);
      const isValidShelterId = shelterIds?.includes(data?.selectedShelterId);

      if (!isValidShelterId) {
        handleShelterSelection(shelterList?.[0].id);
      } else {
        setSelectedShelterId(data.selectedShelterId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingUser]);

  const value: IShelterLocationContext = {
    shelterList,
    isFetchingShelterList: isFetchingUser,
    selectedShelter,
    selectedShelterId,
    handleShelterSelection,
    isSwitchingLocation,
    setIsSwitchingLocation,
    selectedShelterIdForShelterReporting,
    setSelectedShelterIdForShelterReporting,
  };

  return (
    <ShelterLocationContext.Provider value={value}>
      {children}
    </ShelterLocationContext.Provider>
  );
}

/**
 * A hook to access the ShelterLocationContext
 *
 * @returns {IShelterLocationContext} The shelter location context
 */
export const useShelterLocationContext = (): IShelterLocationContext =>
  useContext(ShelterLocationContext);
