import { PetSpecies } from '@/components/molecules/ShelterPetCard/ShelterPetCard';
import { tomorrow } from '@/lib/utils/helpers/dateHelpers/dates';
import LocalStorage from '@/lib/utils/storage/local-storage';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

/**
 * The banner storage class is used to deal with the storage for the persistent
 * banner
 */
export class BannerStorage {
  /** The storage used in the class */
  readonly storage = new LocalStorage();

  /** The key used for the storage */
  readonly key = 'banner';

  /**
   * A method used to store the banner data in storage
   *
   * @param {IBannerStorage} data - The banner data to store
   */
  public set = (data: IBannerStorage) => {
    this.storage.set(this.key, data, { expires: tomorrow });
  };

  /** A method used to remove the banner data from storage */
  public delete = () => {
    this.storage.delete(this.key);
  };

  /**
   * A method used to get the banner data from storage
   *
   * @returns {IBannerStorage | null} The banner props data
   */
  public get = (): IBannerStorage | null => {
    return this.storage.get(this.key) as IBannerStorage;
  };
}

/**
 * The interface for the banner storage
 *
 * @interface IBannerStorage
 */
interface IBannerStorage {
  /**
   * The type of banner to display
   *
   * @memberof IBannerStorage
   * @member {PersistentBannerTypes} [type]
   */
  type?: PersistentBannerTypes;
  /**
   * The props for the banner
   *
   * @memberof IBannerStorage
   * @member {createAccountBannerProps | petSearchBannerProps} [bannerProps]
   */
  bannerProps?: createAccountBannerProps | petSearchBannerProps;
}

/**
 * The types of banners that can be returned
 *
 * @typedef {string} PersistentBannerTypes
 */
export type PersistentBannerTypes = 'info' | 'warning' | 'neighbors';

/**
 * The types of searches that can be done
 *
 * @typedef {string} SearchTypes
 */
export type SearchTypes = 'distance' | 'photo';

/**
 * The type of pet reports
 *
 * @typedef {string} PetReportTypes
 */
export type PetReportTypes = 'lost' | 'found';

/**
 * The interface for the PersistentBannerHandler props used to display the
 * Create Account Banner
 *
 * @interface createAccountBannerProps
 */
export interface createAccountBannerProps {
  /**
   * The type of pet report
   *
   * @memberof createAccountBannerProps
   * @member {PetReportTypes} petReportType
   */
  petReportType: PetReportTypes;
  /**
   * The type of search
   *
   * @memberof createAccountBannerProps
   * @member {SearchTypes} searchType
   */
  searchType: SearchTypes;
  /**
   * The Pet species
   *
   * @memberof createAccountBannerProps
   * @member {PetSpecies} [species]
   */
  species?: PetSpecies;
}

/**
 * The interface for the PersistentBannerHandler props used to display the Pet
 * Search Banner
 *
 * @interface petSearchBannerProps
 */
export interface petSearchBannerProps {
  /**
   * The Id of the pet, this is used for analytics
   *
   * @memberof petSearchBannerProps
   * @member {number} [petId]
   */
  petId?: number;
  /**
   * The zip code of the pet, this is used for analytics
   *
   * @memberof petSearchBannerProps
   * @member {string} [zipCode]
   */
  zipCode?: string;
  /**
   * The name of the pet, this is used for analytics
   *
   * @memberof petSearchBannerProps
   * @member {string} [petName]
   */
  petName?: string;
  /**
   * The type of pet report
   *
   * @memberof petSearchBannerProps
   * @member {PetReportTypes} petReportType
   */
  petReportType: PetReportTypes;
  /**
   * The pet data
   *
   * @memberof petSearchBannerProps
   * @member {object} petData
   */
  petData: {
    /**
     * The Pet photo
     *
     * @memberof petSearchBannerProps.petData
     * @member {string} photo
     */
    photo: string;
    /**
     * The pet species
     *
     * @memberof petSearchBannerProps.petData
     * @member {PetSpecies} species
     */
    species: PetSpecies;
    /**
     * The coordinates for the user
     *
     * @memberof petSearchBannerProps.petData
     * @member {object} coordinates
     */
    coordinates: {
      /**
       * The latitude
       *
       * @memberof petSearchBannerProps.petData.coordinates
       * @member {number} latitude
       */
      latitude: number;
      /**
       * The longitude
       *
       * @memberof petSearchBannerProps.petData.coordinates
       * @member {number} longitude
       */
      longitude: number;
    };
    /**
     * The label of the address of the pet
     *
     * @memberof petSearchBannerProps.petData
     * @member {string} [address]
     */
    address?: string;
  };
}

/**
 * The interface for the PersistentBannerProvider props
 *
 * @interface IPersistentBannerContext
 */
export interface IPersistentBannerContext {
  /**
   * The type of banner
   *
   * @memberof IPersistentBannerContext
   * @member {PersistentBannerTypes} [type]
   */
  type?: PersistentBannerTypes;
  /**
   * The props used to create the banner
   *
   * @memberof IPersistentBannerContext
   * @member {createAccountBannerProps | petSearchBannerProps} [bannerProps]
   */
  bannerProps?: createAccountBannerProps | petSearchBannerProps;
  /**
   * The function to set the banner data
   *
   * @memberof IPersistentBannerContext
   * @member {Function} setBannerData
   */
  setBannerData: (
    type?: PersistentBannerTypes,
    bannerProps?: createAccountBannerProps | petSearchBannerProps
  ) => void;
}

const PersistentBannerContext = createContext<IPersistentBannerContext>({
  type: undefined,
  bannerProps: undefined,
  /** The function to set the banner data */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setBannerData: () => {},
});

/**
 * The interface for the PersistentBannerProvider props
 *
 * @interface IPersistentBannerProvider
 */
interface IPersistentBannerProvider {
  /**
   * The children wrapped by the provider
   *
   * @memberof IPersistentBannerProvider
   * @member {ReactNode} children
   */
  children: ReactNode;
}

/**
 * The PersistentBannerProvider component.
 *
 * @param {IPersistentBannerProvider} props - The props for the
 *   PersistentBannerProvider.
 * @returns {Element} - The PersistentBannerProvider.
 */
export function PersistentBannerProvider({
  children,
}: IPersistentBannerProvider): JSX.Element {
  const storage = new BannerStorage();
  const [type, setType] = useState<PersistentBannerTypes>();
  const [bannerProps, setBannerProps] = useState<
    createAccountBannerProps | petSearchBannerProps
  >();

  useEffect(() => {
    const data = storage.get();
    if (data && !type && !bannerProps) {
      setType(data.type);
      setBannerProps(data.bannerProps);
    }
    // This only needs to run on render once since we do not want to continuously look in the storage.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * This function is used to set the banner data
   *
   * @param {PersistentBannerTypes} type - The type of banner
   * @param {createAccountBannerProps | petSearchBannerProps} bannerProps - The
   *   props for the banner
   */
  const setBannerData = (
    type?: PersistentBannerTypes,
    bannerProps?: createAccountBannerProps | petSearchBannerProps
  ): void => {
    setType(type);
    setBannerProps(bannerProps);
    storage.set({ type, bannerProps });
  };

  return (
    <PersistentBannerContext.Provider
      value={{ type, bannerProps, setBannerData }}
    >
      {children}
    </PersistentBannerContext.Provider>
  );
}

/**
 * A hook to access the PersistentBannerContext
 *
 * @returns {IPersistentBannerContext} - The PersistentBannerContext
 */
export const usePersistentBannerContext = (): IPersistentBannerContext =>
  useContext(PersistentBannerContext);
