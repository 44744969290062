import PetAndOwnerCard from '@/components/molecules/PetAndOwnerCard/PetAndOwnerCard';
import { addHttps } from '@/lib/utils/stringReplace/addHttps';
import { ContainerProps } from '..';

/**
 * @param {ContainerProps['petAndOwnerCard']} props - Component props
 * @returns {React.ReactNode} - PetAndOwnerCardContainer component
 */
export const PetAndOwnerCardContainer = (
  props: ContainerProps['petAndOwnerCard']
) => (
  <PetAndOwnerCard
    key={props.sys.id}
    petName={props.fields.petName}
    ownerName={props.fields.ownerName}
    description={props.fields.copy}
    image={addHttps(props.fields.image?.fields.file?.url)}
    link={props.fields.actionLink?.fields.url || ''}
  />
);
